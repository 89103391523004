/*=======contact form styles========*/
	div.wpcf7 {
		margin: 0;
		padding: 0;
	}

	div.wpcf7 .screen-reader-response {
		position: absolute;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		width: 1px;
		margin: 0;
		padding: 0;
		border: 0;
	}

	div.wpcf7-response-output {
		margin: 2em 0.5em 1em;
		padding: 0.2em 1em;
		// border: 2px solid #ff0000;
	}

	div.wpcf7-mail-sent-ok {
		border: 2px solid #398f14;
	}

	div.wpcf7-mail-sent-ng,
	div.wpcf7-aborted {
		border: 2px solid #ff0000;
	}

	div.wpcf7-spam-blocked {
		border: 2px solid #ffa500;
	}

	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing {
		border: 2px solid #f7e700;
	}

	.wpcf7-form-control-wrap {
		position: relative;
	}

	span.wpcf7-not-valid-tip {
		color: #f00;
		font-size: 1em;
		font-weight: normal;
		display: block;
	}

	.use-floating-validation-tip span.wpcf7-not-valid-tip {
		position: absolute;
		top: 20%;
		left: 20%;
		z-index: 100;
		border: 1px solid #ff0000;
		background: #fff;
		padding: .2em .8em;
	}

	span.wpcf7-list-item {
		display: inline-block;
		margin: 0 0 0 1em;
	}

	span.wpcf7-list-item-label::before,
	span.wpcf7-list-item-label::after {
		content: " ";
	}

	.wpcf7-display-none {
		display: none;
	}

	div.wpcf7 .ajax-loader {
		visibility: hidden;
		display: inline-block;
		background-image: url('../img/ajax-loader.gif');
		background-size: 200%;
	    background-position: center;
	    width: 50px;
	    height: 100%;
		border: none;
		padding: 0;
		margin: 0 0 0 4px;
		vertical-align: middle;
		position: absolute;
	    right: 4px;
	    top: 0px;
	}

	div.wpcf7 .ajax-loader.is-active {
		visibility: visible;
	}

	div.wpcf7 div.ajax-error {
		display: none;
	}

	div.wpcf7 .placeheld {
		color: #888;
	}

	div.wpcf7 input[type="file"] {
		cursor: pointer;
	}

	div.wpcf7 input[type="file"]:disabled {
		cursor: default;
	}

	div.wpcf7 .wpcf7-submit:disabled {
		cursor: not-allowed;
	}
/*=====end contact form styles=======*/
@import "../libs/instag/instag-slider.css";
// @import "../../../../plugins/instagram-slider-widget/assets/css/instag-slider.css";